<template>
  <div id="VideoGallery">
    <div class="header">
      <span>VIDEO GALLERY</span>
      <h2>影音專區</h2>
    </div>

    <div class="list">
      <template v-for="item in video_data">
        <router-link
          :to="`/video/${item.video_id}`"
          class="card"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-duration="1000"
          :key="item.video_id"
        >
          <div class="img_box">
            <img :src="item.cover" />
          </div>
          <p class="title">{{ item.title }}</p>
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoGallery',
  components: {},
  data() {
    return {
      video_data: [],
    };
  },
  methods: {
    async GetVideoData() {
      let result = await this.SendGetData(
        process.env.VUE_APP_BASE_API + 'videos/get_video_list.php'
      );
      if (result != 'error') {
        let data = JSON.parse(result.data);
        this.video_data = data;
        this.video_data.sort((a, b) => {
          return a.position - b.position;
        });
        this.$nextTick(() => {
          window.prerenderReady = true;
        });
      }
    },
  },
  async created() {
    this.GetVideoData();
  },
  metaInfo() {
    return {
      title: '影音專區｜欣富通｜SIFUTON',
      meta: [
        {
          property: 'og:description',
          content:
            '欣富通成立於1993年，專注於生產機械停車和油壓電梯高品質的昇降系統，用專業知識及技術創新來服務國內產業界，一直為客戶提供安全可靠和耐用持久的產品，隨著時間的累積，我們對客戶的需求持續關注使我們在各種應用積累了經驗，增加了我們的產品組合，並成為昇降系統應用的領先製造商。',
          vmid: 'og:description',
        },
        {
          property: 'twitter:description',
          content:
            '欣富通成立於1993年，專注於生產機械停車和油壓電梯高品質的昇降系統，用專業知識及技術創新來服務國內產業界，一直為客戶提供安全可靠和耐用持久的產品，隨著時間的累積，我們對客戶的需求持續關注使我們在各種應用積累了經驗，增加了我們的產品組合，並成為昇降系統應用的領先製造商。',
          vmid: 'twitter:description',
        },
        {
          property: 'description',
          content:
            '欣富通成立於1993年，專注於生產機械停車和油壓電梯高品質的昇降系統，用專業知識及技術創新來服務國內產業界，一直為客戶提供安全可靠和耐用持久的產品，隨著時間的累積，我們對客戶的需求持續關注使我們在各種應用積累了經驗，增加了我們的產品組合，並成為昇降系統應用的領先製造商。',
          vmid: 'description',
        },
        {
          property: 'og:image',
          content: 'https://www.sifuton.com.tw/img/share/index.jpg',
          vmid: 'og:image',
        },
        {
          property: 'apple-mobile-web-app-title',
          content: '影音專區｜欣富通｜SIFUTON',
          vmid: 'apple-mobile-web-app-title',
        },
        {
          property: 'application-name',
          content: '影音專區｜欣富通｜SIFUTON',
          vmid: 'application-name',
        },
        {
          property: 'og:site_name',
          content: '影音專區｜欣富通｜SIFUTON',
          vmid: 'og:site_name',
        },
        {
          property: 'og:type',
          content: 'website',
          vmid: 'og:type',
        },
        {
          property: 'og:locale',
          content: 'zh_tw',
          vmid: 'og:locale',
        },
      ],
    };
  },
};
</script>
